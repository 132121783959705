import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import './App.scss';
import Calendar from './components/Calendar';
import Footer from './components/Footer';

const holidayLists = [
  {
    bulan: 'Januari',
    listcuti: [
      {
        negeri: 'Semua Negeri kecuali Johor, Kedah, Kelantan, Perlis & Terengganu',
        tarikh: '1 Jan',
        hari: 'Jumaat',
        cuti: 'Tahun Baru'
      },
      {
        negeri: 'N. Sembilan',
        tarikh: '14 Jan',
        hari: 'Khamis',
        cuti: 'Hari Keputeraan YDPB N. Sembilan'
      },
      {
        negeri: 'Johor, Kuala Lumpur, N. Sembilan, Pulau Pinang, Perak, Putrajaya & Selangor',
        tarikh: '28 Jan',
        hari: 'Khamis',
        cuti: 'Hari Thaipusam'
      }
    ]
  },
  { 
    bulan: 'Februari',
    listcuti: [
      {
        negeri: 'Kuala Lumpur, Labuan & Putrajaya',
        tarikh: '1 Feb',
        hari: 'Isnin',
        cuti: 'Hari Wilayah Persekutuan'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '12 Feb',
        hari: 'Jumaat',
        cuti: 'Tahun Baru Cina'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '13 Feb',
        hari: 'Sabtu',
        cuti: 'Tahun Baru Cina'
      },
      {
        negeri: 'Johor, Kedah & Terengganu',
        tarikh: '14 Feb',
        hari: 'Ahad',
        cuti: 'Cuti Tahun Baru Cina'
      }
    ]
  },
  { 
    bulan: 'Mac',
    listcuti: [
      {
        negeri: 'Terengganu',
        tarikh: '4 Mac',
        hari: 'Khamis',
        cuti: 'Hari Ulang Tahun Pertabalan Sultan Terengganu'
      },
      {
        negeri: 'Kedah, N. Sembilan, Perlis & Terengganu',
        tarikh: '11 Mac',
        hari: 'Khamis',
        cuti: 'Israk dan Mikraj'
      },
      {
        negeri: 'Johor',
        tarikh: '23 Mac',
        hari: 'Selasa',
        cuti: 'Hari Keputeraan Sultan Johor'
      },
    ]
  },
  { 
    bulan: 'April',
    listcuti: [
      {
        negeri: 'Sabah & Sarawak',
        tarikh: '2 Apr',
        hari: 'Jumaat',
        cuti: 'Good Friday'
      },
      {
        negeri: 'Johor, Kedah & Melaka',
        tarikh: '13 Apr',
        hari: 'Selasa',
        cuti: 'Awal Ramadan'
      },
      {
        negeri: 'Melaka',
        tarikh: '15 Apr',
        hari: 'Khamis',
        cuti: 'Hari Perisytiharan Melaka Sebagai Bandaraya Bersejarah'
      },
      {
        negeri: 'Terengganu',
        tarikh: '26 Apr',
        hari: 'Isnin',
        cuti: 'Hari Keputeraan Sultan Terengganu'
      },
      {
        negeri: 'Semua Negeri kecuali Johor, Kedah, Melaka, N.Sembilan, Sabah & Sarawak',
        tarikh: '29 Apr',
        hari: 'Khamis',
        cuti: 'Hari Nuzul Al-Quran'
      },
    ]
  },
  { 
    bulan: 'Mei',
    listcuti: [
      {
        negeri: 'Semua Negeri',
        tarikh: '1 Mei',
        hari: 'Sabtu',
        cuti: 'Hari Pekerja'
      },
      {
        negeri: 'Terengganu',
        tarikh: '2 Mei',
        hari: 'Ahad',
        cuti: 'Hari Pekerja'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '13 Mei',
        hari: 'Khamis',
        cuti: 'Hari Raya Aidilfitri'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '14 Mei',
        hari: 'Jumaat',
        cuti: 'Hari Raya Aidilfitri'
      },
      {
        negeri: 'Johor & Kedah',
        tarikh: '16 Mei',
        hari: 'Ahad',
        cuti: 'Cuti Hari Raya Aidilfitri'
      },
      {
        negeri: 'Pahang',
        tarikh: '22 Mei',
        hari: 'Sabtu',
        cuti: 'Hari Hol Pahang'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '26 Mei',
        hari: 'Rabu',
        cuti: 'Hari Wesak'
      },
      {
        negeri: 'Labuan & Sabah',
        tarikh: '30 Mei',
        hari: 'Ahad',
        cuti: 'Pesta Kaamatan'
      },
      {
        negeri: 'Labuan & Sabah',
        tarikh: '31 Mei',
        hari: 'Isnin',
        cuti: 'Pesta Kaamatan'
      },
    ]
  },
  { 
    bulan: 'Jun',
    listcuti: [
      {
        negeri: 'Sarawak',
        tarikh: '1 Jun',
        hari: 'Selasa',
        cuti: 'Hari Gawai'
      },
      {
        negeri: 'Sarawak',
        tarikh: '2 Jun',
        hari: 'Rabu',
        cuti: 'Hari Gawai'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '7 Jun',
        hari: 'Isnin',
        cuti: 'Hari Keputeraan YDP Agong'
      },
      {
        negeri: 'Kedah',
        tarikh: '20 Jun',
        hari: 'Ahad',
        cuti: 'Hari Keputeraan Sultan Kedah'
      },
    ]
  },
  { 
    bulan: 'Julai',
    listcuti: [
      {
        negeri: 'Pulau Pinang',
        tarikh: '7 Jul',
        hari: 'Rabu',
        cuti: 'Hari Bandar Warisan Dunia Georgetown'
      },
      {
        negeri: 'Pulau Pinang',
        tarikh: '10 Jul',
        hari: 'Sabtu',
        cuti: 'Harijadi Yang di-Pertua Negeri Pulau Pinang'
      },
      {
        negeri: 'Perlis',
        tarikh: '17 Jul',
        hari: 'Sabtu',
        cuti: 'Hari Keputeraan Raja Perlis'
      },
      {
        negeri: 'Terengganu',
        tarikh: '19 Jul',
        hari: 'Isnin',
        cuti: 'Hari Arafah'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '20 Jul',
        hari: 'Selasa',
        cuti: 'Hari Raya Haji'
      },
      {
        negeri: 'Kedah, Kelantan, Perlis & Terengganu',
        tarikh: '21 Jul',
        hari: 'Rabu',
        cuti: 'Hari Raya Haji'
      },
      {
        negeri: 'Sarawak',
        tarikh: '22 Jul',
        hari: 'Khamis',
        cuti: 'Hari Sarawak'
      },
      {
        negeri: 'Pahang',
        tarikh: '30 Jul',
        hari: 'Jumaat',
        cuti: 'Hari Keputeraan Sultan Pahang'
      },
    ]
  },
  { 
    bulan: 'Ogos',
    listcuti: [
      {
        negeri: 'Semua Negeri',
        tarikh: '10 Ogs',
        hari: 'Selasa',
        cuti: 'Awal Muharram'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '31 Ogs',
        hari: 'Selasa',
        cuti: 'Hari Merdeka'
      },
    ]
  },
  { 
    bulan: 'September',
    listcuti: [
      {
        negeri: 'Johor',
        tarikh: '13 Sep',
        hari: 'Isnin',
        cuti: 'Hari Hol Almarhum Sultan Iskandar'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '16 Sep',
        hari: 'Khamis',
        cuti: 'Hari Malaysia'
      },
    ]
  },
  { 
    bulan: 'Oktober',
    listcuti: [
      {
        negeri: 'Sabah',
        tarikh: '2 Okt',
        hari: 'Sabtu',
        cuti: 'Harijadi Yang di-Pertua Negeri Sabah'
      },
      {
        negeri: 'Melaka',
        tarikh: '8 Okt',
        hari: 'Jumaat',
        cuti: 'Harijadi Yang di-Pertua Negeri Melaka'
      },
      {
        negeri: 'Sarawak',
        tarikh: '9 Okt',
        hari: 'Sabtu',
        cuti: 'Harijadi Yang di-Pertua Negeri Sarawak'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '19 Okt',
        hari: 'Selasa',
        cuti: 'Maulidur Rasul'
      },
    ]
  },
  { 
    bulan: 'November',
    listcuti: [
      {
        negeri: 'Semua Negeri kecuali Sarawak',
        tarikh: '4 Nov',
        hari: 'Khamis',
        cuti: 'Hari Deepavali'
      },
      {
        negeri: 'Perak',
        tarikh: '5 Nov',
        hari: 'Jumaat',
        cuti: 'Hari Keputeraan Sultan Perak'
      },
      {
        negeri: 'Kelantan',
        tarikh: '11 Nov',
        hari: 'Khamis',
        cuti: 'Hari Keputeraan Sultan Kelantan'
      },
      {
        negeri: 'Kelantan',
        tarikh: '12 Nov',
        hari: 'Jumaat',
        cuti: 'Hari Keputeraan Sultan Kelantan Hari Kedua'
      },
    ]
  },
  { 
    bulan: 'Disember',
    listcuti: [
      {
        negeri: 'Selangor',
        tarikh: '11 Dis',
        hari: 'Sabtu',
        cuti: 'Hari Keputeraan Sultan Selangor'
      },
      {
        negeri: 'Sabah',
        tarikh: '24 Dis',
        hari: 'Jumaat',
        cuti: 'Cuti Hari Natal'
      },
      {
        negeri: 'Semua Negeri',
        tarikh: '25 Dis',
        hari: 'Sabtu',
        cuti: 'Hari Krismas'
      },
      {
        negeri: 'Terengganu',
        tarikh: '26 Dis',
        hari: 'Ahad',
        cuti: 'Cuti Hari Krismas'
      },
    ]
  }
]

function App() {
  
  return (
    <div className="App">
      <div className="wrapper">
        <header>
          <section className="page-banner">
            <img src="/images/main-banner.jpg" alt=""/>
          </section>
        </header>
        <section className="home-page">
          <section className="sec-holidayTable">
            <Container>
              <Row>
                <h1>Jadual Cuti Umum Malaysia 2021</h1>
                <p>Jadual ini dikemaskini pada 9 Jan 2021. Sumber rasmi dari <a href="http://www.kabinet.gov.my/bkpp/pdf/hari_kelepasan_am/hka_2021.pdf">kabinet.gov.my</a></p>
                <Col md={12}>
                  <Row className="holidayTable-box">
                    <Col md={12}>
                      {holidayLists.map((holidayLists)=>(
                        <Row key={holidayLists.bulan}>
                          <Col md={2} className="boxbulan">
                            <h4>{holidayLists.bulan}</h4>
                          </Col>
                          <Col md={10}>
                            <div className="table-responsive">
                              <Table striped bordered className="table-holiday">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">Tarikh</th>
                                    <th scope="col">Hari</th>
                                    <th scope="col">Cuti</th>
                                    <th scope="col">Negeri Terlibat</th>
                                  </tr>
                                </thead>
                                <tbody >
                                  {holidayLists.listcuti.map((listcuti, index)=>(
                                    <Calendar negeri={listcuti.negeri} tarikh={listcuti.tarikh} hari={listcuti.hari} cuti={listcuti.cuti} key={index} />
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                    {/* <Col md={1}></Col> */}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
      </div>
      <Footer/>
    </div>
  );
}

export default App;