import React from 'react';
import { Container, Row } from "react-bootstrap";

function Footer(){
    return(
        <footer>
            <section className="main-footer">
                <Container>
                <Row>
                    <div className="footerBox">
                    <small className="credit">@RahimanRahim - My first React project</small>
                    <small>Copyright© {new Date().getFullYear()} | <a href="https://www.jadualcuti.com">jadualcuti.com</a></small>
                    </div>
                </Row>
                </Container>
            </section>
        </footer>
    )
}

export default Footer;