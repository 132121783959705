import React from 'react';

function Calendar({negeri, tarikh, hari, cuti}){
  return(
    <tr>
      <td>{tarikh}</td>
      <td>{hari}</td>
      <td>{cuti}</td>
      <td>{negeri}</td>
    </tr>
  );
}

export default Calendar;